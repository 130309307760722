<template>
  <v-container>

    <v-row justify="end" v-if="isAdmin">
      <v-col cols="auto">
        <v-btn color="success" @click="addNotifier()">
          Создать уведомление
        </v-btn>
      </v-col>
    </v-row>

    <filter-panel
      v-model="filter"
      prefix-var="notifier_filter_v1"
      @submit="search()"
      :expanded="true"
    >
      <template #filters>
        <v-row>
          <v-col class="col-3">
            <v-autocomplete
              :items="users"
              multiple
              clearable
              deletable-chips
              small-chips
              item-text="display_name"
              item-value="id"
              v-model="filter.users" dense hide-details label="Пользователи" outlined/>
            </v-col>
            <v-col class="col-3">
              <v-autocomplete
                :items="roles"
                multiple
                clearable
                deletable-chips
                small-chips
                item-text="name"
                item-value="id"
                v-model="filter.roles" dense hide-details label="Роли" outlined/>
            </v-col>
            <v-col class="col-3">
              <v-autocomplete
                :items="chats"
                multiple
                clearable
                deletable-chips
                small-chips
                item-text="title"
                item-value="id"
                v-model="filter.chats" dense hide-details label="Чаты" outlined/>
            </v-col>
        </v-row>
      </template>
    </filter-panel>

    <v-row>
      <v-simple-table>
        <thead>
        <tr>
          <th v-for="column in columns">{{ column.label }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in notifiers">
          <td v-for="column in columns" @click="$router.push(`/admin/notifiers/${row.id}`)">
            <div v-if="isAdmin && column.name == ''">
              <v-btn x-small @click.stop="deleteNotifier(row.id)">
                Удалить
              </v-btn>
            </div>
            <div v-html="row[column.name]"></div>
            <div v-if="column.name==='description'">
              <div class="d-flex my-1 align-cente" v-if="row['users']?.length > 0">
                <!-- <span class="chips-title">Пользователи:</span> -->
                <v-chip x-small v-for="user in row['users']">{{ user.display_name }}</v-chip>
              </div>
              <div class="d-flex my-1 align-cente" v-if="row['roles']?.length > 0">
                <!-- <span class="chips-title">Роли:</span> -->
                <v-chip x-small v-for="role in row['roles']">{{ role.name }}</v-chip>
              </div>
              <div class="d-flex my-1 align-center" v-if="row['chats']?.length > 0">
                <!-- <span class="chips-title">Чаты:</span> -->
                <v-chip x-small v-for="chat in row['chats']">{{ chat.title }}</v-chip>
              </div>
              
              
              
            </div>
          </td>
        </tr>
        </tbody>
        </v-simple-table>

<!--      <v-col class="grid">-->
<!--        <v-card-->
<!--          v-for="notifier in notifiers" :key="notifier.id">-->
<!--          <v-card-actions v-if="isAdmin" align="right">-->
<!--            <small>#{{ notifier.id }}</small>-->
<!--            <v-spacer />-->
<!--            <v-btn text small-->
<!--              :to="{ name: 'AdminNotifierCard', params: { id: notifier.id }}">-->
<!--              Изменить-->
<!--            </v-btn>-->
<!--            <v-btn text small color="error"-->
<!--              @click="deleteNotifier(notifier.id)">-->
<!--              Удалить-->
<!--            </v-btn>-->
<!--          </v-card-actions>-->
<!--          <v-card-title>{{ notifier.title }}</v-card-title>-->
<!--          <v-card-subtitle>{{ notifier.name }}</v-card-subtitle>-->
<!--          <v-card-text v-html="notifier.description" />-->
<!--        </v-card>-->
<!--      </v-col>-->

    </v-row>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NotifiersResource from "@/resources/NotifiersResource"
import notify from '@/components/notifications/notify'
import DialogConfirm from "@/components/dialogs/dialog-confirm";
// import FilterPanel from "@/components/FilterPanel.vue";
import UsersResource from "@/resources/UsersResource";

export default {
  components: {
    FilterPanel: () => import('@/components/FilterPanel'),
  },
  data() {
    return {
      filter: {},
      notifiers: [],          // массив уведомлений
      users: [],
      roles: [],
      chats: [],
    }
  },
  async mounted() {
    await this.getNotifiers()
    await this.getMiscellaneous()
  },
  computed: {
    ...mapGetters(['isAdmin']),
    columns() {
      return [
        {name: 'id', label: "ID"},
        {name: 'name', label: "Ключ"},
        {name: 'title', label: "Заголовок"},
        {name: 'description', label: "Описание"},
        {name: ''}
      ]
    }

  },
  methods: {
    ...mapActions(['loading']),
    /**
     * Получить уведомления
     */
    async getNotifiers () {
      this.loading()
      try {
        const response = await NotifiersResource.list({
          ...this.filter,
        })
        if (response.status && response.status !== 200) throw response

        this.notifiers = response
      } catch (error) {
        console.error(error)
        notify.show({
          message: 'Ошибка при получении списка уведомлений',
          color: 'error'
        })
      } finally {
        this.loading(false)
      }
    },
    /**
     * Создать пустое уведомление и перейти
     */
    async addNotifier () {
      this.loading()
      try {
        const response = await NotifiersResource.store()
        if (response.status && response.status !== 201) throw response

        notify.show({ message: 'Уведомление создано!'})
        this.$router.push({name: 'AdminNotifierCard', params: { id: response.id}})
      } catch (error) {
        console.error(error)
        notify.show({
          message: 'Ошибка при создании уведомления',
          color: 'error'
        })
      } finally {
        this.loading(false)
      }
    },
    /**
     * Удалить уведомление
     * @param {*} id ID уведомления
     */
    deleteNotifier(id) {
      DialogConfirm.show({ message: `Удалить уведомление #${id}?`}, async () => {
        this.loading()
        try {
          const response = await NotifiersResource.delete(id)
          if (response.status && response.status !== 200) throw response

          notify.show({ message: 'Уведомление удалено!'})
          this.getNotifiers()
        } catch (error) {
          console.error(error)
          notify.show({
          message: 'Ошибка при удалении уведомления',
          color: 'error'
        })
        } finally {
          this.loading(false)
        }
      })
    },
    async getMiscellaneous() {
      try {
        const {data: users} = await NotifiersResource.get('/api/users', {
          all: 1,
          active: 1,
          order_by: 'surname',
          compact: true,
        })
        if (users.status && users.status !== 200) throw users

        this.users = users

        const roles = await NotifiersResource.get('/api/roles', {
          compact: true,
        })
        if (roles.status && roles.status !== 200) throw roles

        this.roles = roles

        const chats = await NotifiersResource.get('/api/chats')
        if (chats.status && chats.status !== 200) throw chats

        this.chats = chats
      } catch (error) {
        console.error(error)
        notify.show({
          message: 'Ошибка при получении данных уведомления',
          color: 'error'
        })
      }
    },
    search() {
      this.getNotifiers()
    }
  },
}
</script>

<style scoped lang="sass">
.chips-title
  font-size: .8em
  color: grey
</style>

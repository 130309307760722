window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}

const apiClient = axios.create(
    {
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        timeout: 60000 * 5,
        headers: headers,
    }
)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

apiClient.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        throw error;
    }
);

export default apiClient;
